import React, { useEffect, useRef, useState } from "react";
import "./CustomVideoPlayer.css";
import videojs from "video.js";

const CustomVideoPlayer = (props) => {
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { src, poster, isMobile, handleVideoChange, userData, isFromExplayer } =
    props;
  const [isVisibleButtons, setIsVisibleButtons] = useState(true);

  useEffect(() => {
    const vjplayer = videojs(videoRef.current, {
      poster: poster,
      autoplay: true,
      controls: true,
      // preload: "auto",
      responsive: true,
      fluid: true,
      displayCurrentQuality: true,
      displayQualitySelector: true,
      displayBitrateOption: true,
      controlText: {
        //vjs-menu-item-text
      },
      sources: [
        {
          src: src,
          type: "application/x-mpegURL",
          withCredentials: true,
        },
      ],
      html5: {
        nativeAudioTracks: true,
        nativeVideoTracks: true,
        nativeTextTracks: true,
      },
      userActions: {
        doubleClick: true, // Enable double-tap fullscreen
        hotkeys: true, // Allow hotkeys for control
      },
      controlBar: {
        // fullscreenToggle: true,
        playToggle: true, // Show play/pause button
        volumePanel: {
          inline: true,
        },
      },
    });

    setPlayer(vjplayer);
  }, [src, poster]);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleFullScreen = () => {
    console.log("fullscreen");
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    }
  };

  const handleVolumeChange = (e) => {
    videoRef.current.volume = e.target.value;
  };

  const handleBack10Seconds = () => {
    if (player) {
      player.currentTime(player.currentTime() - 10);
    }
  };
  const handleForward10Seconds = () => {
    if (player) {
      player.currentTime(player.currentTime() + 10);
    }
  };
  const handlePlayPause = () => {
    if (player) {
      if (player.paused()) {
        setIsPlaying(true);
        player.play();
        setIsVisibleButtons(true);
      } else {
        setIsPlaying(false);
        player.pause();
        setIsVisibleButtons(false);
      }
    }
  };

  return (
    <div className="video-container">
      <video ref={videoRef} className="video" src={src} />
      {/* <video
        ref={videoRef}
        // className="video-js vjs-default-skin"
        className="video"
        playsInline
        style={{
          width: "100%",
          height: isMobile ? "25%" : "100%",  
        }}
        src={src}
      /> */}
      {/* <div className="controls">
        <button onClick={togglePlayPause}>
          {isPlaying ? "Pause" : "Play"}
        </button>
        <button onClick={handleFullScreen}>Full Screen</button>
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          onChange={handleVolumeChange}
        />
      </div> */}

      {isVisibleButtons && (
        <div
          // ref={overlayRef}
          className="controls"
          style={{
            display: "flex",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "#00000000", //"#00000000",
          }}
        >
          <>
            <button className="skip-button" onClick={handleBack10Seconds}>
              <img
                src="./tenBack.jpg"
                className="d-inline-block align-top rounded-circle"
                style={{
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#00000000",
                }}
                alt="./tenBack.jpg"
              />
            </button>

            <button className="skip-button" onClick={handlePlayPause}>
              {player && !player.paused() ? (
                <img
                  src="./pause.jpg"
                  className="d-inline-block align-top rounded-circle"
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#00000000",
                  }}
                  alt="./pause.jpg"
                />
              ) : (
                <img
                  src="./bebuPlay_icon.png"
                  className="d-inline-block align-top rounded-circle"
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#00000000",
                  }}
                  alt="./bebuPlay_icon.png"
                />
              )}
            </button>

            <button className="skip-button" onClick={handleForward10Seconds}>
              <img
                src="./tenFarw.jpg"
                className="d-inline-block align-top rounded-circle"
                style={{
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#00000000",
                }}
                alt="./tenFarw.jpg"
              />
            </button>
          </>
        </div>
      )}
      <button onClick={handleFullScreen}>Full Screen</button>
    </div>
  );
};

export default CustomVideoPlayer;
