import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const PaymentStatusPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    ReactPixel.track("Subscription_success", {
      label: "Subscribe",
    });

    // Redirect after 2 seconds
    const timer = setTimeout(() => {   
      window.location.href =
        // "https://play.google.com/store/apps/details?id=com.bebu.app";
        "https://bit.ly/3Y3XOM1";
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-5" style={{ marginTop: "15rem" }}>
          <div
            className="message-box _success"
            style={{
              boxShadow: "0 15px 25px #00000019",
              padding: "45px",
              width: "100%",
              textAlign: "center",
              margin: "40px auto",
              borderBottom: "solid 4px #3FBDA2",
            }}
          >
            {/* Logo image */}
            <img
              src="https://www.bebu.app/bebu_header.png"
              className="d-inline-block align-top"
              alt="Logo"
              style={{ height: "40px", marginBottom: "30px" }}
            />
            {/* Success message */}
            <p
              style={{
                color: "white",
                marginBottom: "0px",
                fontSize: "18px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              Your order is being processed. Please allow a few minutes for
              confirmation.
              <br />
              We are redirecting you to your profile.
              <br />
              Thank you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatusPage;
