import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./videoJsPlayer.css";

const VideoJsPlayer = (props) => {
  const demolink =
    "https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8";
  const videoRef = useRef(null);
  const overlayRef = useRef(null);
  const location = useLocation();
  // const handle = useFullScreenHandle();
  // const { src } = location.state;
  const navigate = useNavigate();
  const { src, poster, isMobile, handleVideoChange, userData, isFromExplayer } =
    props;
  // console.log("in the videojs player props are,,,", props);
  const [player, setPlayer] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleButtons, setIsVisibleButtons] = useState(true);

  const [showFull, setShowFull] = useState(
    window.matchMedia("(max-width: 700px)").matches
  );
  const [currentUser, setCurrentUser] = useState(null);
  const [orientation, setOrientation] = useState(window.orientation);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  useEffect(() => {
    const vjplayer = videojs(videoRef.current, {
      poster: poster,
      autoplay: true,
      controls: true,
      // preload: "auto",
      responsive: true,
      fluid: true,
      displayCurrentQuality: true,
      displayQualitySelector: true,
      displayBitrateOption: true,
      controlText: {
        //vjs-menu-item-text
      },
      sources: [
        {
          src: src,
          type: "application/x-mpegURL",
          withCredentials: true,
        },
      ],
      html5: {
        nativeAudioTracks: true,
        nativeVideoTracks: true,
        nativeTextTracks: true,
      },
      // userActions: {
      //   doubleClick: true, // Enable double-tap fullscreen
      //   hotkeys: true, // Allow hotkeys for control
      // },
      // controlBar: {
      //   // fullscreenToggle: true,
      //   playToggle: true, // Show play/pause button
      //   volumePanel: {
      //     inline: true,
      //   },
      // },
    });

    setPlayer(vjplayer);
    changeVideo(src, poster);
  }, [src, poster]);

  useEffect(() => {
    // const handleOrientationChange = () => {
    //   setOrientation(window.orientation);
    // };

    // const handleOrientationChange = () => {
    //   if (window.orientation === 90 || window.orientation === -90) {
    //     handleFullscreenToggle();
    //   } else {
    //     handleExitFullscreen();
    //   }
    // };

    // window.addEventListener("orientationchange", handleOrientationChange);

    // if (orientation === 90) {
    //   if (player) {
    //     // player.requestFullscreen();
    //     player
    //       .requestFullscreen()
    //       .then(() => {
    //         if (isMobile) {
    //           setIsVisibleButtons(true); // Show buttons when entering fullscreen
    //         }
    //       })
    //       .catch((error) => {
    //         console.error("Failed to enter fullscreen:", error);
    //       });
    //     // player.requestFullscreen();
    //     player.currentTime(player.currentTime());
    //     // setIsFullscreen(true);
    //   } else {
    //     player.currentTime(player.currentTime());
    //   }
    // } else {
    //   if (player) {
    //     player.currentTime(player.currentTime());
    //     // setIsFullscreen(false);
    //   }
    // }

    if (!isMobile) {
      if (player != null) {
        if (player && !player.paused()) {
          setIsPlaying(true);
        } else {
          setIsPlaying(false);
        }
      }
    }

    // const handleGlobalTap = () => {
    //   console.log("Global tap detected!");
    //   setIsVisibleButtons(true);
    // };

    // document.addEventListener("touchstart", handleGlobalTap);
    // document.addEventListener("click", handleGlobalTap);

    if (isFullscreen) {
      setIsVisibleButtons(true);
    }
    // console.warn(isFullscreen);
    return () => {
      // window.removeEventListener("orientationchange", handleOrientationChange);
      // document.removeEventListener("touchstart", handleGlobalTap);
      // document.removeEventListener("click", handleGlobalTap);
    };
  }, [orientation, isFullscreen, isMobile, player]);

  useEffect(() => {
    const handleGlobalTap = () => setIsVisibleButtons(true);
    document.addEventListener("pointerdown", handleGlobalTap);
    return () => document.removeEventListener("pointerdown", handleGlobalTap);
  }, []);

  useEffect(() => {
    if (isFromExplayer) {
      // if (!player.paused()) {
      const video = videoRef.current;
      if (video) {
        video.muted = true; // Mute the video to allow autoplay
        setIsPlaying(true);
        // }
      }
    }
  }, [isFromExplayer]);

  useEffect(() => {
    if (userData != null) {
      setCurrentUser(userData);
      // console.warn(userData, "FROM PLAYER");
    }
    //console.log("....SRC: ", src);
    let timeout;
    // if (!isVisible && !player.paused()) {
    //   timeout = setTimeout(() => {
    //     setIsVisible(true);
    //   }, 3000); // 5 seconds in milliseconds
    // }
    if (isVisibleButtons && player && player.play()) {
      timeout = setTimeout(() => {
        if (isPlaying) {
          setIsVisibleButtons(false);
        }
      }, 3000); // 5 seconds in milliseconds
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isVisibleButtons, isVisible, player, src]);

  // Function to handle going back 10 seconds
  const handleBack10Seconds = () => {
    if (player) {
      player.currentTime(player.currentTime() - 10);
    }
  };

  // Function to handle video change
  const changeVideo = (newVideoUrl, poster) => {
    handleVideoChange(newVideoUrl, poster);
    if (player) {
      //console.log("....PLAYER,:", newVideoUrl, poster);
      player.src(newVideoUrl);
      player.poster(poster);
      // player.currentTime(player.currentTime() - player.currentTime());
    }
  };

  // Function to handle playing or pausing the video
  const handlePlayPause = () => {
    if (player) {
      if (player.paused()) {
        setIsPlaying(true);
        player.play();
        setIsVisibleButtons(true);
      } else {
        setIsPlaying(false);
        player.pause();
        setIsVisibleButtons(false);
      }
    }
  };

  useEffect(() => {
    const updateShowFull = () => {
      setShowFull(window.matchMedia("(max-width: 700px)").matches);
    };
    window.addEventListener("resize", updateShowFull);
    return () => window.removeEventListener("resize", updateShowFull);
  }, []);

  useEffect(() => {
    // if (player) {
    //   player.hlsQualitySelector({ displayCurrentQuality: true });
    // }
  }, [player]);

  const enterFullscreen = () => {
    if (videoRef.current) {
      videoRef.current
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
          setIsVisibleButtons(true);
        })
        .catch((error) => console.error("Fullscreen entry failed:", error));
    }
  };

  const exitFullscreen = () => {
    document
      .exitFullscreen()
      .then(() => {
        setIsFullscreen(false);
        setIsVisibleButtons(false);
      })
      .catch((error) => console.error("Fullscreen exit failed:", error));
  };

  const handleFullscreenToggle = () => {
    isFullscreen ? exitFullscreen() : enterFullscreen();
  };

  // Function to handle forwarding 10 seconds
  const handleForward10Seconds = () => {
    if (player) {
      player.currentTime(player.currentTime() + 10);
    }
  };

  // useEffect(() => {
  //   const handleOrientationChange = () => {
  //     setOrientation(window.orientation);
  //   };

  //   const handleFullscreenChange = () => {
  //     const isFull = !!document.fullscreenElement;
  //     setIsFullscreen(isFull);
  //   };

  //   window.addEventListener("orientationchange", handleOrientationChange);
  //   document.addEventListener("fullscreenchange", handleFullscreenChange);

  //   return () => {
  //     window.removeEventListener("orientationchange", handleOrientationChange);
  //     document.removeEventListener("fullscreenchange", handleFullscreenChange);
  //   };
  // }, []);

  // const handleFullscreenToggle = () => {
  //   const player = videoRef.current;
  //   if (!isFullscreen) {
  //     player.requestFullscreen();
  //     setIsVisibleButtons(true);
  //   } else {
  //     document.exitFullscreen();
  //   }
  // };

  // const handleFullscreenToggle = () => {
  //   const player = videoRef.current;

  //   if (!isFullscreen) {
  //     if (player) {
  //       player
  //         .requestFullscreen()
  //         .then(() => {
  //           if (isMobile) {
  //             setIsVisibleButtons(true); // Show buttons when entering fullscreen
  //             setIsFullscreen(true); // Update fullscreen state
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Failed to enter fullscreen:", error);
  //         });
  //     }
  //   } else {
  //     document
  //       .exitFullscreen()
  //       .then(() => {
  //         // setIsVisibleButtons(false); // Hide buttons when exiting fullscreen
  //         setIsFullscreen(false); // Update fullscreen state
  //       })
  //       .catch((error) => {
  //         console.error("Failed to exit fullscreen:", error);
  //       });
  //   }

  //   if (!isFullscreen) {
  //     if (player) {
  //       player
  //         .requestFullscreen()
  //         .then(() => {
  //           setIsFullscreen(true);
  //           setIsVisibleButtons(true); // Show buttons in fullscreen mode
  //           console.log("enter full");
  //         })
  //         .catch((error) => {
  //           console.error("Failed to enter fullscreen:", error);
  //         });
  //     }
  //   } else {
  //     console.log("exit full");
  //     handleExitFullscreen();
  //   }
  // };

  const handleExitFullscreen = () => {
    document
      .exitFullscreen()
      .then(() => {
        setIsFullscreen(false);
        setIsVisibleButtons(false); // Hide buttons when exiting fullscreen
      })
      .catch((error) => {
        console.error("Failed to exit fullscreen:", error);
      });
  };

  const handleVideoClick = () => {
    console.log("Overlay button clicked");
    // Handle button click event
  };

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className="video-js vjs-default-skin"
        playsInline
        style={{
          width: "100%",
          height: isMobile ? "25%" : "100%",
        }}
        src={src}
      />
      {/* <FullScreen handle={handle}>Any fullscreen content here</FullScreen> */}
      <button onClick={handleFullscreenToggle}>
        {/* {isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"} */}
      </button>
      {isFullscreen && (
        <div className="fullscreen-overlay">
          <button className="overlay-button" onClick={handleVideoClick}>
            Click Me
          </button>
        </div>
      )}
      {/* {isFullscreen && (
        <div className="fullscreen-overlay">
          <button className="overlay-button" onClick={handleVideoClick}>
            Click Me
          </button>
        </div>
      )} */}

      {/* {showOverlay && <div className="overlay-new">Overlay Content</div>} */}
      {isMobile ? (
        <div
          ref={overlayRef}
          style={{
            display: "flex",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "22%",
            backgroundColor: "#00000000", //"#00000000",
          }}
          onClick={() => setIsVisibleButtons(true)}
        >
          {/* {isFullscreen && isVisibleButtons && isFromExplayer && (
            <>
              <button className="skip-button" onClick={handleBack10Seconds}>
                <img
                  src="./tenBack.jpg"
                  className="d-inline-block align-top rounded-circle"
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#00000000",
                  }}
                  alt="./tenBack.jpg"
                />
              </button>
              <button className="skip-button" onClick={handlePlayPause}>
                {player && !player.paused() ? (
                  <img
                    src="./pause.jpg"
                    className="d-inline-block align-top rounded-circle"
                    style={{
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#00000000",
                    }}
                    alt="./pause.jpg"
                  />
                ) : (
                  <img
                    src="./bebuPlay_icon.png"
                    className="d-inline-block align-top rounded-circle"
                    style={{
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#00000000",
                    }}
                    alt="./bebuPlay_icon.png"
                  />
                )}
              </button>
              <button className="skip-button" onClick={handleForward10Seconds}>
                <img
                  src="./tenFarw.jpg"
                  className="d-inline-block align-top rounded-circle"
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#00000000",
                  }}
                  alt="./tenFarw.jpg"
                />
              </button>
            </>
          )} */}
          {isVisibleButtons ? (
            <div
              ref={overlayRef}
              style={{
                display: "flex",
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "#00000000", //"#00000000",
              }}
            >
              <>
                <button className="skip-button" onClick={handleBack10Seconds}>
                  <img
                    src="./tenBack.jpg"
                    className="d-inline-block align-top rounded-circle"
                    style={{
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#00000000",
                    }}
                    alt="./tenBack.jpg"
                  />
                </button>
                <button className="skip-button" onClick={handlePlayPause}>
                  {player && !player.paused() ? (
                    <img
                      src="./pause.jpg"
                      className="d-inline-block align-top rounded-circle"
                      style={{
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#00000000",
                      }}
                      alt="./pause.jpg"
                    />
                  ) : (
                    <img
                      src="./bebuPlay_icon.png"
                      className="d-inline-block align-top rounded-circle"
                      style={{
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#00000000",
                      }}
                      alt="./bebuPlay_icon.png"
                    />
                  )}
                </button>

                <button
                  className="skip-button"
                  onClick={handleForward10Seconds}
                >
                  <img
                    src="./tenFarw.jpg"
                    className="d-inline-block align-top rounded-circle"
                    style={{
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#00000000",
                    }}
                    alt="./tenFarw.jpg"
                  />
                </button>
              </>
            </div>
          ) : (
            // <>
            //   <Button className="skip-button" onClick={handleBack10Seconds}>
            //     <img
            //       src="./tenBack.jpg"
            //       className="d-inline-block align-top rounded-circle"
            //       style={{
            //         borderRadius: "50%",
            //         width: "50px",
            //         height: "50px",
            //         backgroundColor: "#00000000",
            //       }}
            //       alt="./tenBack.jpg"
            //     />
            //   </Button>
            //   <Button className="skip-button" onClick={handlePlayPause}>
            //     {player && !player.paused() ? (
            //       <img
            //         src="./pause.jpg"
            //         className="d-inline-block align-top rounded-circle"
            //         style={{
            //           borderRadius: "50%",
            //           width: "50px",
            //           height: "50px",
            //           backgroundColor: "#00000000",
            //         }}
            //         alt="./pause.jpg"
            //       />
            //     ) : (
            //       <img
            //         src="./bebuPlay_icon.png"
            //         className="d-inline-block align-top rounded-circle"
            //         style={{
            //           borderRadius: "50%",
            //           width: "50px",
            //           height: "50px",
            //           backgroundColor: "#00000000",
            //         }}
            //         alt="./bebuPlay_icon.png"
            //       />
            //     )}
            //   </Button>

            //   <Button
            //     className="skip-button"
            //     onClick={handleForward10Seconds}
            //   >
            //     <img
            //       src="./tenFarw.jpg"
            //       className="d-inline-block align-top rounded-circle"
            //       style={{
            //         borderRadius: "50%",
            //         width: "50px",
            //         height: "50px",
            //         backgroundColor: "#00000000",
            //       }}
            //       alt="./tenFarw.jpg"
            //     />
            //   </Button>
            // </>
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      {isVisible &&
        (isMobile ? (
          <></>
        ) : (
          // <div
          //   ref={overlayRef}
          //   style={{
          //     display: "flex",
          //     position: "absolute",
          //     top: "0",
          //     left: "0",
          //     width: "100%",
          //     height: "22%",
          //     backgroundColor: "red",
          //   }}
          // >
          //   <Button className="skip-button" onClick={handleBack10Seconds}>
          //     <img
          //       src="./tenBack.jpg"
          //       className="d-inline-block align-top rounded-circle"
          //       style={{
          //         borderRadius: "50%",
          //         width: "50px",
          //         height: "50px",
          //         backgroundColor: "#00000000",
          //       }}
          //       alt="./tenBack.jpg"
          //     />
          //   </Button>
          //   <Button className="skip-button" onClick={handlePlayPause}>
          //     {player && !player.paused() ? (
          //       <img
          //         src="./pause.jpg"
          //         className="d-inline-block align-top rounded-circle"
          //         style={{
          //           borderRadius: "50%",
          //           width: "50px",
          //           height: "50px",
          //           backgroundColor: "#00000000",
          //         }}
          //         alt="./pause.jpg"
          //       />
          //     ) : (
          //       <img
          //         src="./bebuPlay_icon.png"
          //         className="d-inline-block align-top rounded-circle"
          //         style={{
          //           borderRadius: "50%",
          //           width: "50px",
          //           height: "50px",
          //           backgroundColor: "#00000000",
          //         }}
          //         alt="./bebuPlay_icon.png"
          //       />
          //     )}
          //   </Button>

          //   <Button className="skip-button" onClick={handleForward10Seconds}>
          //     <img
          //       src="./tenFarw.jpg"
          //       className="d-inline-block align-top rounded-circle"
          //       style={{
          //         borderRadius: "50%",
          //         width: "50px",
          //         height: "50px",
          //         backgroundColor: "#00000000",
          //       }}
          //       alt="./tenFarw.jpg"
          //     />
          //   </Button>
          // </div>
          <div
            // ref={overlayRef}
            ref={videoRef}
            style={{
              display: "flex",
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "90%",
              backgroundColor: "#00000000",
            }}
          >
            <button className="skip-button-web-1" onClick={handleBack10Seconds}>
              <img
                src="./tenBack.jpg"
                className="d-inline-block align-top rounded-circle"
                style={{
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#00000000",
                }}
                alt="./tenBack.jpg"
              />
            </button>
            <button className="skip-button-web" onClick={handlePlayPause}>
              {isPlaying ? (
                <img
                  src="./pause.jpg"
                  className="d-inline-block align-top rounded-circle"
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#00000000",
                  }}
                  alt="./pause.jpg"
                />
              ) : (
                <img
                  src="./bebuPlay_icon.png"
                  className="d-inline-block align-top rounded-circle"
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#00000000",
                  }}
                  alt="./bebuPlay_icon.png"
                />
              )}
            </button>

            <button
              className="skip-button-web"
              onClick={handleForward10Seconds}
            >
              <img
                src="./tenFarw.jpg"
                className="d-inline-block align-top rounded-circle"
                style={{
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#00000000",
                }}
                alt="./tenFarw.jpg"
              />
            </button>
          </div>
        ))}
    </div>
    // <div data-vjs-player>
    //   <video
    //     ref={videoRef}
    //     className="video-js vjs-default-skin"
    //     playsInline
    //     style={{ width: "100%", height: isMobile ? "25%" : "100%" }}
    //     src={src} // Provide the video source URL
    //   />
    // </div>
  );
};

export default VideoJsPlayer;
